import React from 'react';
import './About.css';

function About() {
  return (
    <div className="page">
      <h1>Coming Soon</h1>
    </div>
  );
}

export default About;
